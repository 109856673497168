/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from 'axios';
import IProviderDTO from '../interfaces/ProviderDTO';
import { getToken, IUser, logout } from './auth';

interface ICreateUser {
  name: string;
  nameUser?: string;
  email: string;
  password: string;
  type: string;
  status?: string;
  providerId?: string;
}

interface IProviderFilter {
  orderByCreatedAt?: 'ASC' | 'DESC';
  companyName?: string;
  status?: string;
  name?: string;
  typePerson?: string;
  cnpj?: string;
  cpf?: string;
  orderByCompanyName?: 'ASC' | 'DESC';
  minRent?: number;
  maxRent?: number;
}

interface IStoreProviderFilter {
  companyName?: string;
  condition?: string;
  maximumValuePlan?: number;
  minimumValuePlan?: number;
  planId?: string;
  knowledges?: string;
  levelOfLearning?: string;
  sellerId?: string;
}

interface IItemsFilter {
  id?: string;
  status?: string;
  title?: string;
  provider?: string;
  levelOfLearning?: string | string[];
  orderByCreatedAt?: 'ASC' | 'DESC';
  orderByTitle?: 'ASC' | 'DESC';
  notStatus?: string;
  statusItemContent?: string;
  idItem?: string;
  idItemContent?: string;
  knowledges?: string | string[];
  size?: number;
}

interface IStoreItemsFilter {
  id?: string;
  status?: string;
  title?: string;
  companyName?: string;
  provider?: string;
  providerId?: string;
  planId?: string;
  levelOfLearning?: string | string[];
  orderByCreatedAt?: 'ASC' | 'DESC';
  orderByTitle?: 'ASC' | 'DESC';
  notStatus?: string;
  statusItemContent?: string;
  idItem?: string;
  idItemContent?: string;
  knowledges?: string | string[];
}

interface IFilterUser {
  name?: string;
  email?: string;
  // status?: ['active', 'inative', 'exclused', 'pending', 'draft', undefined];
  status?: string;
  type?: string | Array<string>;
  orderName?: 'ASC' | 'DESC';
  orderDate?: 'ASC' | 'DESC';
  providerId?: string;
}

export interface IGetKnowledge {
  label: string;
  value: string;
  type: string;
}

interface ICreateItem {
  id?: string;
  itemId?: string;
  authors?: Array<string>;
  mediaUrl?: string;
  questions?: string;
  bibliographicReference?: Array<string>;
  introduction?: string;
  learningObjectives?: Array<string>;
  synthesis?: string;
  title?: string;
  levelOfLearning?: Array<string>;
  knowledgeUniversity?: number;
  knowledges?: Array<string>;
  topics: string;
  updateMessage?: string;
}

interface IFilterReviewer {
  id?: string;
  status?: string;
  active?: boolean;
  managerStatus?: string;
  itemContentId?: string;
  itemContentTitle?: string;
  orderByItemTitle?: 'ASC' | 'DESC';
  orderByCreatedAt?: 'ASC' | 'DESC';
}

interface ICreateReview {
  id?: string;
  managerId: string;
  reviewerId: string;
  curatorId: string;
  itemIds?: Array<string>;
  contentIds: Array<string>;
}

interface IFilterInternalTeam {
  name: string;
  email: string;
  status: 'active' | 'inative' | 'exclused' | 'pending' | 'draft';
  orderName: string;
  orderDate: string;
  providerId: string;
  type: [
    'admin',
    'director',
    'manager',
    'curator',
    'review',
    'seller',
    'client',
    'user',
    'provider',
    'serviceProvider',
  ];
}

interface IComment {
  reviewId: string;
  itemId: string;
  comment: string;
}

interface IChangeStatus {
  ids: Array<string>;
  status: string;
  comment?: string | boolean;
}

interface IChangeConditionProvider {
  provider_id: string;
  condition: string;
}

interface IPutSellers {
  status?: string;
}

interface IGetSellers {
  id: string;
  order?: string;
  // ordernate: string;
  status?: string;
  companyName?: string;
}

interface ICreateRent {
  sellerId: string;
  paymentMethod: string;
  providerIds: string | string[];
}

interface ICreateRentByAdmin {
  sellerId: string;
  providerId: string;
  planId: string;
}

interface ICreateRentPlans {
  sellerId: string;
  providerId: string;
  planId: string;
}

interface ISignupSeller {
  id?: string;
  sellerId?: string;
  nameUser: string;
  email: string;
  password?: string;
  sellerName: string;
  cnpj: string;
}
interface IUpdateSeller {
  id: string;
  email: string;
  name: string;
  cnpj: string;
}

interface IFilterPlans {
  providerId?: string;
  planId?: string;
  id?: string;
  name?: string;
  description?: string;
  minRent?: string;
  maxRent?: string;
}

interface IPlanPrice {
  id?: string;
  planId: string;
  providerId: string;
  value: string;
}

interface IChangePlanPrice {
  id: string;
  planId: string;
  providerId: string;
  price: string;
}

interface ICreateSellerSubject {
  name?: string;
  sellerId?: string;
}

interface IOrderSellerSubjectItem {
  subjectItemId?: string;
  order?: number;
}

interface ICreateSellerSubjectItem {
  subjectId?: string;
  itemId?: string;
  itemContentId?: string;
}

interface IOrdenateCourses {
  orderedItems?: string[];
}

interface IOrdenateCourses {
  orderedItems?: string[];
}

interface IPutSellersStatus {
  status: string;
}

interface ISellersSearch {
  order: string;
  status?: string;
  companyName?: string;
}

interface IFindReview {
  id: string;
  type: 'review' | 'content';
}

interface IPostService {
  id?: string;
  title: string;
  description: string;
  link?: string;
  price: string;
  image?: string;
}

interface IGetGallery {
  provider_id: string;
  type: 'images' | 'pdf';
}

interface IGalleriesResponse {
  id: string;
  name: string;
}

interface IFilesResponse {
  id: string;
  filename: string;
  url: string;
}

interface IPartnerNotification {
  name: string;
  message: string;
  to: string;
  from?: string;
  subject: string;
}

interface IUploadFile {
  formData: FormData;
  type: 'images' | 'pdf' | 'provider' | 'questions' | 'infographics';
  provider_id: string;
}

interface IGetListFilterSellerSubject {
  sellerId?: string;
  name?: string;
  knowledges?: string;
  levelOfLearning?: string;
}

interface ICreateSubjectCategory {
  name: string;
  seller_id: string;
  color: string;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const lmsApi = axios.create({
  baseURL: process.env.REACT_APP_LMS_API,
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

// Deslogar se houver 401
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      logout();
      window.location.href = window.location.origin;
    }
    return Promise.reject(error);
  },
);

export default api;

// Routes
export const postSignUp = (data: any) =>
  api.post('/create', data, {
    validateStatus: () => true,
  });
export const postSignIn = (data: any) => api.post('/signin', data);
export const postResetPassword = (data: any) =>
  api.post('/update-password', data);
export const postForgotPassword = (data: any) =>
  api.post('/forgot-password', data);

// Users
export const getUsers = () => api.get('/users');

export const filterUsers = (data: IFilterUser) => {
  return api.get(`/users/filter`, {
    params: {
      ...data,
    },
  });
};

export const getUsersFilter = async (data: IFilterInternalTeam) => {
  return api.get(`/users/filter`, {
    params: {
      ...data,
    },
  });
};

export const postCreateUser = (data: ICreateUser) =>
  api.post('/signup', {
    ...data,
    status: 'inative',
  });

export const getShowUser = (userId: string) => {
  return api.get(`/users/view/${userId}`);
};

export const putUpdateUser = (data: Partial<IUser>, userId?: string) => {
  return api.put(`/users/update/${data.id}`, data);
};

export const putUpdateMyUser = (data: Partial<IUser>) => {
  return api.put(`/users/update`, data);
};

export const putUpdateUserSeller = (data: Partial<IUser>, userId: string) => {
  return api.put(`/sellers/my-team/${userId}`, data);
};

export const deleteUserSeller = (userId: string) => {
  return api.delete(`/sellers/my-team/${userId}`);
};

export const putChangeStatusUser = (data: IChangeStatus) =>
  api.put('/users/status', {
    ids: data.ids,
    status: data.status,
  });

export const putSendPicture = (id: string, data: any, config: any) =>
  api.put(`/user/avatar/${id}`, data, config);

export const deleteAvatar = (id: string) =>
  api.delete(`/users/avatar/delete/${id}`);

export const getConfirmEmail = (email: string) =>
  api.get(`/confirmed`, {
    params: {
      email,
    },
  });

// SELLER
export const getTeamSeller = () => {
  return api.get('sellers/my-team');
};

export const generateLmsKey = (id: string) => {
  return api.put('/sellers/lms/key', {
    id,
  });
};

export const sendEmailLmsKey = () => {
  return api.post('/sellers/request/lms/key');
};

export const postCreateTermAcceptance = (seller_id: string) => {
  return api.post(`/sellers/accepted-terms`, {
    seller_id,
  });
};

// == Plans Seller
export const createNewPlanSeller = (sellerId: string) => {
  return api.post(`/sellers/plans-seller/create`, {
    seller_id: sellerId,
  });
};

export const getPlansSeller = (sellerId: string) => {
  return api.get(`/sellers/plans-seller/${sellerId}`);
};

// Providers
export const postCreateEditor = (data: any) => {
  return api.post('/providers/editor/create', {
    ...data,
  });
};

export const getProvider = () => {
  return api.get(`/providers`);
};

export const getProviderTeam = () => {
  return api.get('/providers/my-team');
};

export const getProviderById = (id: string) => {
  return api.get(`/providers/view/${id}`);
};

export const filterProvider = (data: IProviderFilter) => {
  return api.get(`/providers/search`, {
    params: {
      ...data,
    },
  });
};

export const storeFilterProvider = (data: IStoreProviderFilter) => {
  return api.get(`/providers/filter`, {
    params: {
      ...data,
    },
  });
};

export const putChangeStatusProvider = (data: IChangeStatus) => {
  return api.put(`/providers/status`, {
    status: data.status,
    ids: data.ids,
  });
};

export const putChangePlanProvider = ({
  provider_id,
  condition,
}: IChangeConditionProvider) => {
  return api.put(`/providers/condition/${provider_id}`, {
    condition,
  });
};

export const getPlansProvider = (data: IFilterPlans) => {
  return api.get(`/providers/plans/search`, {
    params: {
      ...data,
    },
  });
};

export const postCreatePlansProvider = (data: IFilterPlans) => {
  return api.post(`/providers/plans/create`, data);
};

export const putPlansProvider = (data: IFilterPlans) => {
  return api.put(`/providers/plans/update/${data.id}`, data);
};

export const providerRentsGraph = (finalDate?: Date, initialDate?: Date) => {
  return api.get('/providers/dashboard/monthly-income', {
    params: { finalDate, initialDate },
  });
};

export const providerApprovedItems = () => {
  return api.get('/providers/dashboard/approved-items');
};

export const providerRecentRents = () => {
  return api.get('/providers/dashboard/recent-rents');
};

export const providerMostPopularUnitys = () => {
  return api.get('/providers/dashboard/most-popular-items');
};

// PROVIDER PRICE ======
export const getPlansProviderPrice = (data: IFilterPlans) => {
  return api.get(`/providers/plans-value/search`, {
    params: {
      ...data,
    },
  });
};

export const postPlansProviderPrice = (data: IPlanPrice) => {
  return api.post(`/providers/plans-value/create`, data);
};

export const putPlansProviderPrice = (data: IPlanPrice) => {
  return api.put(`/providers/plans-value/update/${data.id}`, data);
};

// Items
export const getItemsProvider = (data: any) => {
  return api.get(`/items/item/content/find/provider`, {
    params: { ...data },
  });
};

export const getItemsAdm = () => {
  return api.get(`/items/item/find/admin`);
};

export const filterItemsProvider = (data: IItemsFilter) => {
  return api.get(`/items/item/find/versions`, {
    params: { ...data },
  });
};

export const filterStoreItemsProvider = (data: IStoreItemsFilter) => {
  return api.get(`/items/item/find/provider`, {
    params: { ...data },
  });
};

export const filterStoreItemsAvailableItems = (data: IStoreItemsFilter) => {
  return api.get(`/items/item/find/available-items`, {
    params: { ...data },
  });
};

export const getItemsContent = (data: string) => {
  return api.get(`/items/item/content/find/${data}`);
};

export const putItemsStatus = (data: any) => {
  return api.put(`/items/item/status`, {
    item: {
      ids: data.ids,
      status: data.status,
    },
  });
};

export const putItemsContentStatus = (data: any) => {
  return api.put(`/items/item/content/status/`, {
    item: {
      ids: data.ids,
      status: data.status,
    },
  });
};

export const postItemRecory = (id: string) => {
  return api.post(`items/item/content/recover/${id}`);
};

// itemsFeedback
export const getItemsFeedback = () => {
  return api.get(`/items/item/feedback`);
};

export const changeFeedbackStatus = (id: string, status: string) => {
  const itemToSend = {
    item: {
      id,
      status,
    },
  };
  return api.put(`/items/item/feedback/status`, itemToSend);
};

export const getKnowledge = async (): Promise<IGetKnowledge[]> => {
  const { data: knowledge } = await api.get(`/items/knowledge/find`);
  const res = knowledge.content.map((know: any) => {
    return {
      label: know.title,
      value: know.id,
      type: know.type,
    };
  });
  return res;
};

export const getAccessUnits = (
  idKnowledge: string,
  startDate: Date,
  endDate: Date,
) => {
  const response = api.get(
    `/items/item/content/find/access/units/${idKnowledge}/${startDate}/${endDate}`,
  );
  return response;
};

export const getLevelOfLearning = async () => {
  const { data: res } = await api.get(`/items/constants/levelOfLearning`);
  const level = [
    ...res.basic.map((item: any) => {
      return { label: item, id: item, type: 'basic' };
    }),
    ...res.university.map((item: any) => {
      return { label: item, id: item, type: 'university' };
    }),
  ];

  return level;
};

export const postCreateItem = (data: ICreateItem) => {
  return api.post('/items/item/content/create', {
    item: data,
  });
};

export const putItem = (data: ICreateItem) => {
  return api.put('/items/item/content/update', {
    item: data,
  });
};

// Review
export const filterReviewer = (data: IFilterReviewer) => {
  return api.get('/items/review/find', {
    params: {
      ...data,
    },
  });
};

export const findUnitsPublished = () => {
  return api.get('/items/review/find/units/published');
};

export const getShowReviewer = (data: IFindReview) => {
  return api.get(`/items/review/find/${data.id}`, {
    params: {
      type: data.type,
    },
  });
};

export const filterItemsAdm = (data: IItemsFilter) => {
  return api.get(`/items/item/find/admin`, {
    params: {
      ...data,
    },
  });
};

export const postCreateReview = (data: ICreateReview) => {
  return api.post('/items/review/create', {
    review: data,
  });
};

export const postRecreateReview = (data: ICreateReview) => {
  return api.post('/items/review/reopen', {
    review: data,
  });
};

export const putReviewStatus = (data: IChangeStatus) => {
  return api.put(`/items/review/update/${data.ids.map(id => id)}`, {
    status: data.status,
    comment: data.comment === true ? ' ' : data.comment,
  });
};

export const putEditReview = (data: {
  reviewerId: string;
  curatorId: string;
  reviewIds: Array<string>;
}) => {
  return api.put(`/items/review/this/update/${data.reviewIds.map(id => id)}`, {
    ...data,
  });
};

export const putEdit = (data: {
  id: any;
  levelOfLearning: any;
  knowledges: any;
}) => {
  return api.put(`/items/item/content/update/Knowledge`, {
    item: {
      id: data.id,
      levelOfLearning: data.levelOfLearning,
      knowledges: data.knowledges,
    },
  });
};

export const postCreateComment = (data: IComment) => {
  return api.post(`/items/comment/post`, {
    ...data,
  });
};

export const filterComment = (data: { reviewId: string }) => {
  return api.get(`/items/comment/find/${data.reviewId}`, {
    params: {
      pageSize: 10000,
    },
  });
};

export const getProviderDataById = (idProvider: string) => {
  return api.get(`/providers/view/${idProvider}`);
};

export const getProviderStoreDataById = (
  idProvider: string,
  idPlan: string,
  sellerId: string,
) => {
  return api.get(`/providers/view/${idProvider}/plan/${idPlan}`, {
    params: {
      sellerId,
    },
  });
};

export const editProviderById = (provider: Partial<IProviderDTO>) => {
  return api.put(`/providers/update/${provider.id}`, provider);
};

export const getProviderRents = () => {
  return api.get('/providers/my-rents');
};

// Service Providers
export const postServiceProvider = (data: IPostService) => {
  return api.post('/providers/services', {
    ...data,
  });
};

export const getServiceProvider = () => {
  return api.get('/providers/services');
};

export const editServiceProvider = (service: Partial<IPostService>) => {
  return api.put(`/providers/services/${service.id}`, {
    ...service,
  });
};

export const deleteServiceProvider = (id: string) => {
  return api.delete(`/providers/services/${id}`);
};

// ============== SELLER PROVIDER SERVICE

export const getAllContentsByProviderId = dataForm => {
  return api.get(`/items/item/content/find/all/${dataForm.providerId}`, {
    params: {
      ...dataForm,
    },
  });
};

export const getAllProviders = () => {
  return api.get('providers/services/filter');
};

export const getProviderServiceById = (id: string) => {
  return api.get(`providers/services/provider/${id}`);
};

export const getGallery = async (
  data: IGetGallery,
): Promise<IFilesResponse[]> => {
  try {
    if (data.provider_id === '') {
      return [];
    }

    const { data: galleries } = await api.get(
      `/galleries/provider/${data.provider_id}`,
    );

    const galleryId = galleries.find(
      (item: IGalleriesResponse) => item.name === data.type,
    ).id;

    if (!galleryId) {
      return [];
    }

    const { data: files } = await api.get(`/galleries/details/${galleryId}`);
    return files.files;
  } catch (error) {
    return [];
  }
};

export const uploadFiles = async ({
  formData,
  type,
  provider_id,
}: IUploadFile) => {
  if (provider_id === '') {
    return;
  }

  const { data: galleries } = await api.get(
    `/galleries/provider/${provider_id}`,
  );
  let galleryId = galleries.find(
    (item: IGalleriesResponse) => item.name === type,
  )?.id;
  if (!galleryId) {
    // criar galeria aqui
    const { data: resCreateGalley } = await api.post(`/gallery/create`, {
      provider_id,
      name: type,
    });

    galleryId = resCreateGalley.id;
  }

  if (type === 'infographics') {
    return api.post(`/galleries/upload/infographics/${galleryId}`, formData, {
      headers: {
        'content-type': 'application/zip',
      },
    });
  }

  if (type === 'questions') {
    return api.post(`/galleries/upload/questions/${galleryId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  // formData.append('gallery_id', galleryId);
  return api.post(`/galleries/upload/images/${galleryId}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const getTags = (tagName?: string) => {
  const response = api.get(`/items/tag`, {
    params: {
      tagName,
    },
  });
  return response;
};

export const createTags = (tagNames?: string[]) => {
  const response = api.post(`/items/tag`, {
    names: tagNames,
  });
  return response;
};

export const tagItem = (ids: string[], itemId: string) => {
  return api.put(`/items/tag`, {
    tagIds: ids,
    itemContent: itemId,
  });
};

export const tagDeleteItem = (id: string[], itemId: string) => {
  return api.delete(`/items/tag/${itemId}`, {
    params: {
      tagId: id,
    },
  });
};

export const signupSeller = (data: ISignupSeller) => {
  return api.post(`/sellers/create`, data);
};

export const updateSeller = (data: IUpdateSeller) => {
  return api.put(`sellers/update/${data.id}`, data);
};

export const getSellerDataById = (idSeller: string) => {
  return api.get(`/sellers/view/${idSeller}`);
};

export const getSellerIdByContentManagerUserId = (idSeller: string) => {
  return api.get(`/sellers/view/by-content-manager/${idSeller}`);
};

export const postCreateContentManager = (data: any) => {
  return api.post('/sellers/content-manager/', data);
};

// CART SELLER ===========

export const postServiceCart = (data: Array<string | undefined>) => {
  if (!data) {
    return;
  }
  return api.post('/providers/services/rent', {
    services: data,
  });
};

export const getPaymentMethods = () => {
  return api.get(`/sellers/payment-methods`);
};

export const postCreateRent = (data: ICreateRent) => {
  return api.post(`/sellers/rents`, data);
};

export const postCreateRentByAdmin = (data: ICreateRentByAdmin) => {
  return api.post(`/sellers/rents/createByAdmin`, data);
};

// Created Rent/plans
export const postCreateRentsPlans = (data: ICreateRentPlans) => {
  return api.post(`/sellers/rents/plans-rents`, data);
};

// rents

export const getSellersRents = () => {
  return api.get(`/sellers/rents/`);
};

export const getSellers = (data: IGetSellers) => {
  return api.get(`/sellers/rents/${data.id}`, {
    params: {
      ...data,
    },
  });
};

export const changeSellersRents = (data: IChangePlanPrice) => {
  return api.put(`/sellers/rents/price/${data.id}`, data);
};

export const getAllRentsBySeller = (sellerId: string) => {
  return api.get(`/sellers/rents/findAll/${sellerId}`);
};

// sellers

export const putSellers = (id: string, data: IPutSellers) => {
  return api.put(`/sellers/rents/${id}`, data);
};

export const deleteSellersRent = (rentId: string) => {
  return api.delete(`/sellers/rents/${rentId}`);
};

export const getSellersSearch = (data: ISellersSearch) => {
  return api.get(`/sellers/search`, {
    params: {
      ...data,
    },
  });
};

export const putSellersStatus = (id: string, data: IPutSellersStatus) => {
  return api.put(`/sellers/status/${id}`, data);
};
// SUBJECT SELLER ===========

export const getListSellerSubjectSimple = (sellerId: string) => {
  return api.get(`/sellers/subjects/seller/${sellerId}`);
};

export const getListSellerSubject = (data: IGetListFilterSellerSubject) => {
  return api.get(`/sellers/subjects/filter`, {
    params: {
      ...data,
    },
  });
};

export const postCreateSellerSubject = (data: ICreateSellerSubject) => {
  return api.post(`/sellers/subjects`, data);
};

export const deleteSellerSubject = (subjectId: string) => {
  return api.delete(`/sellers/subjects/${subjectId}`);
};

export const getSellerStudentCount = (lmsKey: string) => {
  return api.get('https://lms-staging.aurea.app/v1/api/sellers/access-count/', {
    headers: {
      lms_key: lmsKey,
    },
  });
};

export const getSellerUnitysToUpdate = () => {
  return api.get('/items/item/check-updates');
};

export const updateSellerUnity = (itemId: string) => {
  return api.put(`/items/item/update-item-version/${itemId}`);
};

export const getSubjectCategories = (SellerId: string) => {
  return api.get(`sellers/subjects/category/${SellerId}`);
};

export const createSubjectCategory = data => {
  return api.post('sellers/subjects/category', data);
};

export const deleteSubjectCategory = (category_id: string | undefined) => {
  return api.delete(`sellers/subjects/category/${category_id}`);
};

// COURSESxSELLERS =================

export const getSellersCourses = () => {
  return api.get('sellers/courses');
};

export const postCreateSellersCourse = data => {
  return api.post('sellers/courses', data);
};

export const putEditSellersCourse = (id: string, name: string) => {
  return api.put(`/sellers/courses/${id}`, { name });
};

export const deleteSellersCourse = (id: string) => {
  return api.delete(`/sellers/courses/${id}`);
};

export const getSellersCourse = (id: string) => {
  return api.get(`/sellers/courses/${id}`);
};

export const patchOrdenateSellersCourses = (
  data: IOrdenateCourses,
  courseId: string,
) => {
  return api.patch(`sellers/courses/reorder/${courseId}`, data);
};

export const postAddSellersCourseItem = (
  subjectId: string,
  courseId: string,
) => {
  return api.post(`/sellers/course-items/`, { subjectId, courseId });
};

export const deleteSellersCourseItem = (
  courseItemId: string,
  courseIdItems: string,
) => {
  return api.delete(`sellers/course-items/${courseItemId}`, {
    data: { courseId: courseIdItems },
  });
};

export const importProviderCourses = (courseId: string) => {
  return api.post(`sellers/courses/import/${courseId}`);
};

// COURSESxPROVIDERS =================

export const getProvidersCourses = () => {
  return api.get('providers/courses');
};

export const getProviderCourses = (providerId: string) => {
  return api.get(`providers/courses/provider/${providerId}`);
};

export const postCreateProvidersCourse = data => {
  return api.post('providers/courses', data);
};

export const putEditProvidersCourse = (id: string, name: string) => {
  return api.put(`providers/courses/${id}`, { name });
};

export const deleteProvidersCourse = (id: string) => {
  return api.delete(`/providers/courses/${id}`);
};

export const getProvidersCourse = (id: string) => {
  return api.get(`/providers/courses/${id}`);
};

export const patchOrdenateProvidersCourses = (
  data: IOrdenateCourses,
  courseId: string,
) => {
  return api.patch(`providers/courses/${courseId}`, data);
};

export const postAddProvidersCourseItem = (
  subjectId: string,
  courseId: string,
) => {
  return api.post(`/providers/course-items/`, { subjectId, courseId });
};

export const deleteProvidersCourseItem = (
  courseItemId: string,
  courseIdItems: string,
) => {
  return api.delete(`providers/course-items/${courseItemId}`, {
    data: { courseId: courseIdItems },
  });
};

// SUBJECTxITEM PROVIDER =========

export const getListProviderSubjects = (providerId: string) => {
  return api.get(`/providers/subjects/provider/${providerId}`);
};

export const getListProviderSubjectsByTitle = (
  providerId: string,
  search: string,
) => {
  return api.get(`/providers/subjects/provider/${providerId}/find`, {
    params: { subjectTitle: search },
  });
};

export const deleteProviderSubject = (subjectId: string) => {
  return api.delete(`/providers/subjects/${subjectId}`);
};
export const deleteProviderSubjectItem = (
  subjectId: string,
  subjectItem: string,
) => {
  return api.delete(`/providers/subjects/${subjectId}/delete-items`, {
    data: {
      subjectItems: [subjectItem],
    },
  });
};
export const postProviderSubject = (name: string) => {
  return api.post('/providers/subjects', { name });
};

export const getProviderSubjectItem = (id: string) => {
  return api.get(`providers/subjects/${id}`);
};

export const postProviderSubjectItems = data => {
  return api.post(`providers/subjects/insert-items`, data);
};

export const putOrdenateProviderSubjectItem = (
  data: Array<IOrderSellerSubjectItem>,
  id: string,
) => {
  return api.put(`/providers/subjects/${id}`, { subjectItems: data });
};

// SUBJECTxITEM SELLER ===========

export const getListSellerSubjectItems = (subjectId: string) => {
  return api.get(`/sellers/subjects/${subjectId}/item`);
};

export const postCreateSellerSubjectItem = (data: ICreateSellerSubjectItem) => {
  return api.post(`/sellers/subjects/item`, data);
};

export const deleteSellerSubjectItem = (subjectItemId: string) => {
  return api.delete(`/sellers/subjects/item/${subjectItemId}`);
};

export const patchOrdenateSellerSubjectItem = (
  subjectItemId: string,
  order: number,
) => {
  return api.patch(`/sellers/subjects/item/${subjectItemId}`, {
    order,
  });
};

export const getAccessCountSeller = () => {
  return api.get(`/sellers/access-count`);
};

export const putOrdenateSellerSubjectItem = (
  data: Array<IOrderSellerSubjectItem>,
) => {
  return api.put(`/sellers/subjects/item`, { subjectItems: data });
};

// ACTIVE PROVIDERS

export const getActiveProvider = () => {
  return api.get(`/sellers/active-providers`);
};

export const updateSellerSubject = (data: {
  id: string;
  name: string;
  category_id?: string;
}) => {
  return api.put('/sellers/subjects/subject', data);
};

export const partnerNotification = (data: IPartnerNotification) => {
  return api.post('providers/services/partner-notification', data);
};

export const downloadUnity = (lmsKey, itemId) => {
  return lmsApi.post(
    '/saveDataInJson',
    { itemId },
    { headers: { lms_key: lmsKey } },
  );
};
