import { AiFillSetting } from 'react-icons/ai';
import {
  IconBooks,
  IconDashboard,
  IconUserAlt,
  IconUsersAlt,
  IconTutorials,
  // IconWallet,
  IconSetting,
  IconOpenBook,
  IconDepartmentStore,
  IconThList,
  IconSchool,
  // IconBuilding,
  IconDocument,
  IconBusiness,
  IconBookStore,
} from '../styles/icons';

export const menu = [
  {
    url: '/dashboard',
    icon: IconDashboard,
    label: 'Painel',
    name: 'dashboard',
  },
  {
    url: '/dashboard/profile',
    icon: IconUserAlt,
    label: 'Meu Perfil',
    name: 'profile',
  },
  {
    url: '/dashboard/units',
    icon: IconBooks,
    label: 'Unidades',
    name: 'units',
  },
  {
    url: '/dashboard/my-services',
    icon: IconSetting,
    label: 'Meus Serviços',
    name: 'my-services',
  },
  {
    url: '/dashboard/my-units',
    icon: IconBooks,
    label: 'Minhas Unidades',
    name: 'my-units',
  },
  {
    url: '/dashboard/my-team',
    icon: IconUsersAlt,
    label: 'Meu Time',
    name: 'my-team',
  },
  {
    url: '/dashboard/plans',
    icon: IconSetting,
    label: 'Planos',
    name: 'plans',
  },
  {
    url: '/dashboard/providers',
    icon: IconBooks,
    label: 'Fornecedores',
    name: 'providers',
  },

  // {
  // url: '/dashboard/wallet',
  // icon: IconWallet,
  // label: 'Wallet',
  // name: 'wallet',
  // },

  // =====================
  // ====== SELLER =======
  // =====================
  {
    url: '/seller/store',
    icon: IconDepartmentStore,
    label: 'Loja',
    name: 'store',
  },
  {
    url: '/seller/store-service',
    icon: IconBusiness,
    label: 'Parceiros',
    name: 'partners',
  },

  {
    url: '/seller/rented-units',
    icon: IconThList,
    label: 'Catálogo',
    name: 'rented-units',
  },
  {
    url: '/seller/manage-subjects',
    icon: IconOpenBook,
    label: 'Disciplinas',
    name: 'manage-subjects',
  },
  {
    url: '/seller/courses',
    icon: IconBooks,
    label: 'Cursos',
    name: 'courses',
  },
  {
    url: '/dashboard/providers-rents',
    icon: IconSchool,
    label: 'Aluguéis',
    name: 'providers-rents',
  },
  {
    url: '/dashboard/access-report',
    icon: IconSchool,
    label: 'Relatório de Acessos',
    name: 'access-report',
  },
  {
    url: '/dashboard/unit-access-report',
    icon: IconSchool,
    label: 'Relatório de Acesso às Unidades',
    name: 'unit-access-report',
  },
  {
    url: '/dashboard/logs-report',
    icon: IconDocument,
    label: 'Relatório de Logs',
    name: 'logs-report',
  },
  {
    url: '/dashboard/feedback-report',
    icon: IconBookStore,
    label: 'Relatório de Feedbacks',
    name: 'feedback-report',
  },
  {
    url: '/dashboard/units-report',
    icon: IconDocument,
    label: 'Relatório de Unidades Publicadas',
    name: 'units-report',
  },
  {
    url: '/dashboard/tutorials',
    icon: IconTutorials,
    label: 'Tutoriais',
    name: 'tutorials',
  },
  {
    url: '/seller/control',
    icon: IconSchool,
    label: 'Instituições',
    name: 'Sellers',
  },

  {
    url: '/dashboard/settings',
    icon: AiFillSetting,
    label: 'Configurações',
    name: 'settings',
  },
];

export const menuPermission = {
  admin: menu.filter(
    item =>
      item.name !== 'my-units' &&
      item.name !== 'wallet' &&
      item.name !== 'my-services' &&
      item.name !== 'store' &&
      item.name !== 'rented-units' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'store-service' &&
      item.name !== 'providers-rents' &&
      item.name !== 'units-report' &&
      item.name !== 'courses' &&
      item.name !== 'tutorials' &&
      item.name !== 'settings',
  ),
  director: menu.filter(
    item =>
      item.name !== 'my-units' &&
      item.name !== 'wallet' &&
      item.name !== 'store-service' &&
      item.name !== 'my-services' &&
      item.name !== 'store' &&
      item.name !== 'rented-units' &&
      item.name !== 'providers-rents' &&
      item.name !== 'units-report' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'courses' &&
      item.name !== 'tutorials' &&
      item.name !== 'unit-access-report' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  manager: menu.filter(
    item =>
      item.name !== 'my-units' &&
      item.name !== 'wallet' &&
      item.name !== 'store-service' &&
      item.name !== 'my-services' &&
      item.name !== 'my-team' &&
      item.name !== 'plans' &&
      item.name !== 'providers' &&
      item.name !== 'units-report' &&
      item.name !== 'store' &&
      item.name !== 'rented-units' &&
      item.name !== 'Sellers' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'providers-rents' &&
      item.name !== 'courses' &&
      item.name !== 'tutorials' &&
      item.name !== 'unit-access-report' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  curator: menu.filter(
    item =>
      item.name !== 'my-units' &&
      item.name !== 'wallet' &&
      item.name !== 'store-service' &&
      item.name !== 'my-services' &&
      item.name !== 'my-team' &&
      item.name !== 'plans' &&
      item.name !== 'providers' &&
      item.name !== 'units-report' &&
      item.name !== 'store' &&
      item.name !== 'providers-rents' &&
      item.name !== 'rented-units' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'Sellers' &&
      item.name !== 'courses' &&
      item.name !== 'tutorials' &&
      item.name !== 'unit-access-report' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  review: menu.filter(
    item =>
      item.name !== 'my-units' &&
      item.name !== 'wallet' &&
      item.name !== 'store-service' &&
      item.name !== 'my-team' &&
      item.name !== 'my-services' &&
      item.name !== 'plans' &&
      item.name !== 'providers' &&
      item.name !== 'units-report' &&
      item.name !== 'store' &&
      item.name !== 'rented-units' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'Sellers' &&
      item.name !== 'providers-rents' &&
      item.name !== 'courses' &&
      item.name !== 'tutorials' &&
      item.name !== 'unit-access-report' &&
      item.name !== 'tutorials' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  provider: menu.filter(
    item =>
      item.name !== 'units' &&
      item.name !== 'plans' &&
      item.name !== 'store-service' &&
      item.name !== 'my-services' &&
      item.name !== 'providers' &&
      item.name !== 'store' &&
      item.name !== 'access-report' &&
      item.name !== 'Sellers' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  serviceProvider: menu.filter(
    item =>
      item.name !== 'my-units' &&
      item.name !== 'units' &&
      item.name !== 'plans' &&
      item.name !== 'store-service' &&
      item.name !== 'providers' &&
      item.name !== 'units-report' &&
      item.name !== 'providers-rents' &&
      item.name !== 'store' &&
      item.name !== 'rented-units' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'my-team' &&
      item.name !== 'Sellers' &&
      item.name !== 'courses' &&
      item.name !== 'tutorials' &&
      item.name !== 'unit-access-report' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  editor: menu.filter(
    item =>
      item.name !== 'units' &&
      item.name !== 'wallet' &&
      item.name !== 'providers-rents' &&
      item.name !== 'my-team' &&
      item.name !== 'store-service' &&
      item.name !== 'my-services' &&
      item.name !== 'plans' &&
      item.name !== 'providers' &&
      item.name !== 'units-report' &&
      item.name !== 'store' &&
      item.name !== 'rented-units' &&
      item.name !== 'manage-subjects' &&
      item.name !== 'Sellers' &&
      item.name !== 'courses' &&
      item.name !== 'access-report' &&
      item.name !== 'logs-report' &&
      item.name !== 'tutorials' &&
      item.name !== 'unit-access-report' &&
      item.name !== 'settings' &&
      item.name !== 'feedback-report',
  ),
  seller: menu.filter(
    item =>
      item.name === 'dashboard' ||
      item.name === 'profile' ||
      item.name === 'store' ||
      // item.name === 'partners' ||
      item.name === 'rented-units' ||
      item.name === 'manage-subjects' ||
      item.name === 'access-report' ||
      item.name === 'logs-report' ||
      item.name === 'tutorials' ||
      item.name === 'courses' ||
      item.name === 'my-team',
  ),
  seller_assistent: menu.filter(
    item =>
      item.name === 'dashboard' ||
      item.name === 'profile' ||
      item.name === 'rented-units' ||
      item.name === 'manage-subjects' ||
      item.name === 'tutorials',
  ),
  seller_content_manager: menu.filter(
    item =>
      item.name === 'dashboard' ||
      item.name === 'profile' ||
      item.name === 'manage-subjects' ||
      item.name === 'rented-units' ||
      item.name === 'courses' ||
      item.name === 'tutorials',
  ),
  client: menu.filter(
    item => item.name === 'dashboard' || item.name === 'profile',
  ),
  user: menu.filter(
    item => item.name === 'dashboard' || item.name === 'profile',
  ),
};
