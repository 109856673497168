/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { FiFrown } from 'react-icons/fi';
import { BeatLoader } from 'react-spinners';

import { generateId } from '../../../utils/generateId';
import { getItemsFeedback, changeFeedbackStatus } from '../../../services/api';

import { AlertWrapper, Container, ButtonConfirm } from './styles';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

interface IItemsFeedback {
  id: string;
  unit_id: string;
  unit_name: string;
  error_type: string;
  error_description: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export enum FeedbackStatus {
  created = 'Criado',
  viewed = 'Visualizado',
  in_analysis = 'Em análise',
  resolved = 'Resolvido',
  closed = 'Fechado',
}

const FeedbacksReport: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<IItemsFeedback[]>([]);

  const getData = useCallback(async () => {
    try {
      const data = await getItemsFeedback();
      const allFeedbacks = data.data;

      const feedbackInProcess = allFeedbacks.filter(
        (feedback: IItemsFeedback) => feedback.status !== 'closed',
      );

      setDataSource([...feedbackInProcess]);
    } catch (error) {
      // console.error(error);

      addToast({
        type: 'error',
        title: 'Atenção!',
        description:
          'Não foi possível realizar esta operação, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, setDataSource]);

  // USAR FUNÇÃO PARA  ADMIN
  const initialize = useCallback(async () => {
    getData();
  }, [getData]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Container>
      <header>
        <h1>Relatório de Feedbacks</h1>
        <span>{user.name}</span>
      </header>

      {/* <Filters>
        <FiltersWrapper onSubmit={data => getData(data)}>
          <button type="submit">
            <FiFilter />
            Filtrar
          </button>

          <Input label="Data Inicial*" required name="start_date" type="date" />
          <Input label="Data Final*" required name="end_date" type="date" />

          <Select name="log_type" placeholder="Ação" options={logTypes} />
        </FiltersWrapper>
      </Filters> */}
      {loading ? (
        <div style={{ textAlign: 'center', padding: '30px' }}>
          <BeatLoader size={16} />
        </div>
      ) : (
        <div className={`content-tabs ${loading ? `hidden` : `slideUp`}`}>
          <table>
            <thead>
              <tr>
                <th> </th>
                <th>Unidade</th>
                <th>Erro</th>
                <th>Descrição</th>
                {/* <th>Status</th> */}
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {dataSource.map(register => (
                <tr key={generateId()}>
                  <td>
                    <ButtonConfirm
                      onClick={() => {
                        changeFeedbackStatus(register.id, 'closed');
                        getData();
                      }}
                    >
                      ✓
                    </ButtonConfirm>
                  </td>

                  <td>{register.unit_name}</td>
                  <td>{register.error_type}</td>
                  <td>{register.error_description}</td>
                  {/* <td>{FeedbackStatus[register.status]}</td> */}
                  <td>
                    {new Intl.DateTimeFormat('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    }).format(new Date(register.created_at))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loading && dataSource.length === 0 && (
        <AlertWrapper>
          <FiFrown size={50} />
          Sem dados para apresentação
        </AlertWrapper>
      )}
    </Container>
  );
};

export { FeedbacksReport };
